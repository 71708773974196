import React, { useState, useEffect } from 'react';
import logo from '../images/logo.png';
import bgImage from '../images/bg.png';

// Sabit partikül pozisyonları
const PARTICLE_POSITIONS = {
  small: Array.from({ length: 20 }, () => Math.random() * 100),
  medium: Array.from({ length: 12 }, () => Math.random() * 100),
  large: Array.from({ length: 8 }, () => Math.random() * 100)
};

const SoonShowcase = () => {
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  });

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
    const targetDate = new Date('2025-01-03T19:00:00').getTime();

    const interval = setInterval(() => {
      const now = new Date().getTime();
      const difference = targetDate - now;

      if (difference > 0) {
        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((difference % (1000 * 60)) / 1000);

        setTimeLeft({ days, hours, minutes, seconds });
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const TimeBox = ({ value, label }) => (
    <div className="group relative">
      <div className="relative flex flex-col items-center">
        <span className="text-7xl md:text-8xl font-bold text-transparent bg-clip-text bg-gradient-to-b from-purple-200 to-purple-400 group-hover:scale-110 transition-all duration-500">
          {value}
        </span>
        <span className="text-base md:text-lg text-purple-300/70 mt-2 tracking-widest font-medium">
          {label}
        </span>
      </div>
    </div>
  );

  const renderParticles = () => (
    <div className="fixed inset-0 pointer-events-none overflow-hidden">
      {/* Küçük parçacıklar */}
      {PARTICLE_POSITIONS.small.map((left, i) => (
        <div
          key={`small-${i}`}
          className="absolute w-1 h-1 rounded-full"
          style={{
            left: `${left}%`,
            bottom: '-5px',
            background: 'rgb(147, 51, 234)',
            opacity: '0.05',
            animation: 'moveUpStraight 15s linear infinite',
            animationDelay: `${i * 2}s`,
          }}
        />
      ))}

      {/* Orta parçacıklar */}
      {PARTICLE_POSITIONS.medium.map((left, i) => (
        <div
          key={`medium-${i}`}
          className="absolute w-1.5 h-1.5 rounded-full"
          style={{
            left: `${left}%`,
            bottom: '-5px',
            background: 'rgb(147, 51, 234)',
            opacity: '0.03',
            animation: 'moveUpStraight 18s linear infinite',
            animationDelay: `${i * 3}s`,
          }}
        />
      ))}

      {/* Büyük parçacıklar */}
      {PARTICLE_POSITIONS.large.map((left, i) => (
        <div
          key={`large-${i}`}
          className="absolute w-2 h-2 rounded-full"
          style={{
            left: `${left}%`,
            bottom: '-5px',
            background: 'rgb(147, 51, 234)',
            opacity: '0.02',
            animation: 'moveUpStraight 20s linear infinite',
            animationDelay: `${i * 4}s`,
          }}
        />
      ))}
    </div>
  );

  return (
    <div 
      className="min-h-screen relative flex items-center justify-center p-4 overflow-hidden"
      style={{
        backgroundImage: `url(${bgImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat'
      }}
    >
      <div className="absolute inset-0 bg-black/50 backdrop-blur-sm" />
      {renderParticles()}

      <div className="relative w-full max-w-7xl mx-auto z-10">
        <div className={`flex flex-col items-center space-y-16 transition-all duration-1000 ${isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`}>
          
          {/* Simplified Logo Area */}
          <div className="relative w-56 h-56 md:w-64 md:h-64 group">
            <div className="absolute -inset-2 rounded-full bg-purple-500/5 blur-xl"></div>
            <img 
              src={logo}
              alt="Astreya Network Logo" 
              className="relative w-full h-full object-contain drop-shadow-[0_0_15px_rgba(168,85,247,0.3)] transform group-hover:scale-105 transition-all duration-500"
            />
          </div>

          {/* Title */}
          <h1 className="text-6xl md:text-7xl text-center font-bold tracking-wider" style={{ fontFamily: 'Space Grotesk, sans-serif' }}>
            <span className="bg-gradient-to-r from-purple-300 via-purple-200 to-purple-300 text-transparent bg-clip-text hover:scale-105 transition-transform duration-300 inline-block">
              ASTREYA NETWORK
            </span>
          </h1>

          {/* Countdown */}
          <div className="grid grid-cols-2 md:grid-cols-4 gap-8 md:gap-16">
            <TimeBox value={timeLeft.days} label="GÜN" />
            <TimeBox value={timeLeft.hours} label="SAAT" />
            <TimeBox value={timeLeft.minutes} label="DAKİKA" />
            <TimeBox value={timeLeft.seconds} label="SANİYE" />
          </div>

          {/* Discord Link & Date */}
          <div className="flex flex-col items-center space-y-8">
            <a 
              href="https://discord.gg/astreya" 
              target="_blank" 
              rel="noopener noreferrer" 
              className="group transform hover:scale-110 transition-all duration-300"
            >
              <svg className="w-12 h-12 text-purple-300 group-hover:text-purple-200 transition-colors duration-300" fill="currentColor" viewBox="0 0 24 24">
                <path d="M20.317 4.37a19.791 19.791 0 00-4.885-1.515.074.074 0 00-.079.037c-.21.375-.444.864-.608 1.25a18.27 18.27 0 00-5.487 0 12.64 12.64 0 00-.617-1.25.077.077 0 00-.079-.037A19.736 19.736 0 003.677 4.37a.07.07 0 00-.032.027C.533 9.046-.32 13.58.099 18.057a.082.082 0 00.031.057 19.9 19.9 0 005.993 3.03.078.078 0 00.084-.028c.462-.63.874-1.295 1.226-1.994a.076.076 0 00-.041-.106 13.107 13.107 0 01-1.872-.892.077.077 0 01-.008-.128 10.2 10.2 0 00.372-.292.074.074 0 01.077-.01c3.928 1.793 8.18 1.793 12.062 0a.074.074 0 01.078.01c.12.098.246.198.373.292a.077.077 0 01-.006.127 12.299 12.299 0 01-1.873.892.077.077 0 00-.041.107c.36.698.772 1.362 1.225 1.993a.076.076 0 00.084.028 19.839 19.839 0 006.002-3.03.077.077 0 00.032-.054c.5-5.177-.838-9.674-3.549-13.66a.061.061 0 00-.031-.03z"/>
              </svg>
            </a>
            <div className="text-xl text-purple-200/70 font-medium tracking-widest">
              3 OCAK, 2025 19.00
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SoonShowcase;